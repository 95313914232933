import { render, staticRenderFns } from "./IssueVarDetails.vue?vue&type=template&id=2237affc&scoped=true"
import script from "./IssueVarDetails.vue?vue&type=script&lang=js"
export * from "./IssueVarDetails.vue?vue&type=script&lang=js"
import style0 from "./IssueVarDetails.vue?vue&type=style&index=0&id=2237affc&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2237affc",
  null
  
)

export default component.exports